import request from "@/utils/http/requset";

function merchantConfigList(data) {
  return request({
    url: "/manage/merchant/search",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
function getPayAccountList(data) {
  return request({
    url: "/manage/payAccount/searchAll",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
function editMerchantConfig(data) {
  return request({
    url: "/manage/merchant/update",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
function saveInMatch(data) {
  return request({
    url: "/manage/merchant/in/match",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
function saveOutMatch(data) {
  return request({
    url: "/manage/merchant/out/match",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}

function rechargeMatch(data) {
  return request({
    url: "/manage/merchant/recharge/match",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}

function saveWhiteOpen(data) {
  return request({
    url: "/manage/merchant/white/open",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
export default {
  merchantConfigList,
  getPayAccountList,
  editMerchantConfig,
  saveInMatch,
  saveOutMatch,
  rechargeMatch,
  saveWhiteOpen
};
